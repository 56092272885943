import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvironmentService } from '@app/core/environment/environment.service';
import { DocumentMetadata, Role, RolesPaginated, User, UserConfig, UsersPaginated } from '@app/compass/interfaces/identity.interface';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { Filter } from '../interfaces/data-model';

@Injectable()
export class IdentityService {

  constructor(
    private http: HttpClient,
    private envService: EnvironmentService,
    private messageService: MessageService,
    private translateService: TranslateService
  ) { }

  importUsersFromAzureAD() {
    return this.http
      .delay(2000)
      .get(`${environment.routes.userImport('azure')}`)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.user.import.title'),
            detail: this.translateService.instant('services.user.import.success')
          });
        })
      )
  }

  getUsers(filters?: Filter[], sort?: Filter, pageSize?: number, pageNumber?: number): Observable<UsersPaginated> {

    var params = new HttpParams();

    if(filters)
      filters.forEach(filter => {
        params = params.append(filter.key, filter.value);
      });

    if(sort)
      params = params.append(sort.key, sort.value);

    if (pageSize !== undefined && pageNumber !== undefined){
      params = params.append("pageNumber", pageNumber);
      params = params.append("pageSize", pageSize);
    }

    return this.http
      .noLoader()
      .delay(2000)
      .cache()
      .get<UsersPaginated>(`${environment.routes.user()}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getUserBirthdays() {
    return this.http
      .noLoader()
      .delay(2000)
      .cache()
      .get<User[]>(`${environment.routes.user()}/birthdays/upcoming`)
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getRoles(userId?: string, pageNumber?: number, pageSize?: number, queryString?: string): Observable<RolesPaginated>{
    var params = new HttpParams();

    if(userId != undefined) {
      params = params.append("userId", userId);
    }

    if(queryString != undefined) {
      params = params.append("queryString", queryString);
    }

    if (pageSize !== undefined && pageNumber !== undefined) {
      params = params.append("pageNumber", pageNumber);
      params = params.append("pageSize", pageSize);
    }

    return this.http
      .noLoader()
      .delay(2000)
      .get<RolesPaginated>(`${environment.routes.role()}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getUserById(userId: string, expand?: string): Observable<User> {

    var params = new HttpParams();

    if (expand)
      params = params.append("expand", expand);

    return this.http
      .noLoader()
      .delay(2000)
      .get<User>(`${environment.routes.user()}/${userId}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  getUserRolesAndPrivileges(userId: string): Observable<User> {
    return this.http
      .noLoader()
      .delay(500)
      .get<User>(`${environment.routes.user()}/${userId}/authorization`);
  }

  getUserDocuments(id: string): Observable<DocumentMetadata[]> {
    return this.http
      .delay(2000)
      .noLoader()
      .get<DocumentMetadata[]>(`${environment.routes.user()}/${id}/documents`);
  }

  updateUser(userId: string, body: User): Observable<User> {
    return this.http
      .noLoader()
      .delay(2000)
      .put<User>(`${environment.routes.user()}/${userId}`, body)
      .pipe(
        map((resp) => {
          if(this.envService.currentUserSubject.getValue()?.id === resp.id)
            this.envService.saveCurrentUser(resp as User);

          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.user.update.title'),
            detail: this.translateService.instant('services.user.update.success')
          });

          return resp;
        })
      );
  }

  updateUserConfigs(userId: string, body: UserConfig){
    return this.http
      .noLoader()
      .delay(2000)
      .patch<UserConfig>(`${environment.routes.configs(userId)}`, body)
      .pipe(
        map((resp) => {
          this.envService.saveConfigs(resp);

          return resp;
        })
      );
  }
}
