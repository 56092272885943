import { Component } from '@angular/core';
import { UserConfig } from '@app/compass/interfaces/identity.interface';
import { Observable } from 'rxjs';
import { LayoutService } from "./service/app.layout.service";
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-footer',
    templateUrl: './app.footer.component.html'
})
export class AppFooterComponent {

    userConfig: Observable<UserConfig | undefined>;

    constructor(
        public layoutService: LayoutService,
        private translate: TranslateService
    ) {
        this.userConfig = this.layoutService.configUpdate$;
    }

    getCopyrights() {
        const now = new Date();
        return this.translate.instant('app.footer.copyrights', { year: now.getFullYear().toString() });
    }
}
