import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { NotfoundComponent } from './compass/components/notfound/notfound.component';
import { AppLayoutComponent } from "./layout/app.layout.component";
import { AuthenticationGuard } from './core/authentication/authentication.guard';
import { AuthorizationGuard } from './core/authentication/authorization.guard';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: 'app', component: AppLayoutComponent,
                children: [
                    { path: 'people', loadChildren: () => import('./compass/components/private/people/people.module').then(m => m.PeopleModule) },
                    { path: 'talent', loadChildren: () => import('./compass/components/private/talent/talent.module').then(m => m.TalentModule) },
                    { path: 'sales', loadChildren: () => import('./compass/components/private/sales/sales.module').then(m => m.SalesModule) },
                    { path: '', redirectTo: 'people', pathMatch: 'full'},
                ],
                canActivate: [AuthenticationGuard, AuthorizationGuard]
            },
            { 
                path: 'auth', loadChildren: () => import('./compass/components/public/auth/auth.module').then(m => m.AuthModule)
            },
            { 
                path: 'profile-generator', loadChildren: () => import('./compass/components/shared/profile-generator/profile-generator.module').then(m => m.ProfileGeneratorModule)
            },
            { 
                path: 'notfound', component: NotfoundComponent
            },
            { 
                path: '', redirectTo: 'app/people', pathMatch: 'full'
            },
            { 
                path: '**', redirectTo: '/notfound'
            },
        ], { scrollPositionRestoration: 'enabled', anchorScrolling: 'enabled', onSameUrlNavigation: 'reload' })
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
