export const ROUTES = {
  /**
  * internal
  */
  appAssets: (folder: string) => `assets/compass/${folder}`,

  /**
  * external
  */
  wordpress: () => `/wp-json/wp/v2`,

  /**
  * Security
  */
  authenticate: () => `/v1/identity/login`,
  authenticateAD: (provider: string) => `/v1/identity/login/${provider}`,
  refresh: () => `/v1/identity/token/refresh`,
  logout: () => `/v1/identity/logout`,

  /**
  * IDENTITY
  */
  address: (userId: string) => `/v1/identity/user/${userId}/address`,
  user: () => `/v1/identity/user`,
  configs: (userId: string) => `/v1/identity/user/${userId}/config`,
  userImport: (provider: string) => `/v1/identity/user/import/${provider}`,
  role: () => `/v1/identity/role`,
  
  //ORG
  org: () => `/v1/identity/org`,
  collection: (type: string) => `/v1/collection/${type}`,

  //SALES
  sales: () => `/v1/sales`,
  lead: () => `/v1/sales/lead`,

  //TALENT
  talent: () => `/v1/talent`,
  applicant: () => `/v1/talent/applicant`,
  job: () => `/v1/talent/job`,
};
