export interface LOV {
  type: string;
  name: string;
  code: string | number;
  lang: string;
}

export enum COLLECTION_TYPE {
  JobPosition = 'JobPosition',
  HardSkill = 'HardSkill',
  SoftSkill = 'SoftSkill',
  MaritalState = 'MaritalState',
  Country = 'Country',
  SalaryType = 'SalaryType',
  InterviewType = 'InterviewType',
  CommentType = 'CommentType',
  BadgeType = 'BadgeType',
  Priority = 'Priority',
  LeadState = 'LeadState',
  LeadType = 'LeadType',
  Recurrence = 'Recurrence',
  Motive = 'Motive',
}

export interface GenericListWrapper<T> {
  list: T[];
  size: number;
}

export interface Filter{
  key: string;
  value: string;
}

export interface Option {
  label: string;
  value: number;
}