import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from "@angular/core";
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { ILevel, LEADSTATE, Lead, LeadCard, LeadRequest, PositionResult, positionThreshold } from "../interfaces/sales.interface";
import { Observable, Subscription, map } from "rxjs";
import { environment } from '@env/environment';
import { CollectionService } from './collection.service';
import { COLLECTION_TYPE, Filter, GenericListWrapper, LOV } from '../interfaces/data-model';


@Injectable()
export class SalesService {

    subscriptions: Subscription = new Subscription();

    constructor(
        private http: HttpClient,
        private messageService: MessageService,
        private translateService: TranslateService,
        private collectionService: CollectionService,
    ) {
    }

    createLead(body: Lead): Observable<Lead> {
        return this.http
            .delay(2000)
            .post<Lead>(`${environment.routes.lead()}`, body)
            .pipe(
                map((resp) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translateService.instant('services.leads.create.title'),
                        detail: this.translateService.instant('services.leads.create.success')
                    });

                    return resp;
                })
            );
    }

    updateLead(id: string, body: Lead): Observable<Lead> {
        return this.http
            .delay(2000)
            .put<Lead>(`${environment.routes.lead()}/${id}`, body)
            .pipe(
                map((resp) => {
                    this.messageService.add({
                        severity: 'success',
                        summary: this.translateService.instant('services.leads.update.title'),
                        detail: this.translateService.instant('services.leads.update.success')
                    });

                    return resp;
                })
            );
    }

    getLeads(managerId?: string, filters?: Filter[], sort?: Filter, pageSize?: number, pageNumber?: number): Observable<GenericListWrapper<LeadRequest>> {
        var params = new HttpParams();

        if (managerId)
            params = params.append('managerId', managerId);

        if (filters)
            filters.forEach(filter => {
                params = params.append(filter.key, filter.value);
            });

        if (sort)
            params = params.append(sort.key, sort.value);

        if (pageSize !== undefined && pageNumber !== undefined) {
            params = params.append("pageNumber", pageNumber);
            params = params.append("pageSize", pageSize);
        }

        return this.http
            .delay(2000)
            .get<GenericListWrapper<Lead>>(`${environment.routes.lead()}`, { params })
            .pipe(
                map((resp) => {
                    return resp;
                })
            );
    }

    getLeadById(id: string): Observable<Lead> {
        var params = new HttpParams();

        return this.http
            .delay(2000)
            .get<Lead>(`${environment.routes.lead()}/${id}`, { params })
            .pipe(
                map((resp) => {
                    return resp;
                })
            );
    }

    // returns 5 observables
    getAllCollections(): Observable<LOV[]>[] {
        let leadTypes: LOV[] = [];
        let priorityTypes: LOV[] = [];
        let leadStateTypes: LOV[] = [];
        let motiveTypes: LOV[] = [];
        let recurrenceTypes: LOV[] = [];

        return [
            this.getLeadTypesCollection().pipe(
                map((resp) => {
                    leadTypes = resp;
                    return resp;
                })
            ),
            this.getPrioritiesCollection().pipe(
                map((resp) => {
                    priorityTypes = resp;
                    return resp;
                })
            ),
            this.getLeadStatesCollection().pipe(
                map((resp) => {
                    leadStateTypes = resp;
                    return resp;
                })
            ),
            this.getMotiveCollection().pipe(
                map((resp) => {
                    motiveTypes = resp;
                    return resp;
                })
            ),
            this.getRecurrenceCollection().pipe(
                map((resp) => {
                    recurrenceTypes = resp;
                    return resp;
                })
            ),
        ];
    }

    getLeadTypesCollection(): Observable<LOV[]> {
        return this.collectionService.getCollection(COLLECTION_TYPE.LeadType);
    }

    getPrioritiesCollection(): Observable<LOV[]> {
        return this.collectionService.getCollection(COLLECTION_TYPE.Priority);
    }

    getLeadStatesCollection(): Observable<LOV[]> {
        return this.collectionService.getCollection(COLLECTION_TYPE.LeadState);
    }

    getRecurrenceCollection(): Observable<LOV[]> {
        return this.collectionService.getCollection(COLLECTION_TYPE.Recurrence);
    }

    getMotiveCollection(): Observable<LOV[]> {
        return this.collectionService.getCollection(COLLECTION_TYPE.Motive);
    }

    arrangeCardPositions(screenHeight: number, screenWidth: number, leads: LeadCard[]): PositionResult {
        leads = leads.filter(lead => lead.lead.leadState !== LEADSTATE.CANCELLED && lead.lead.leadState !== LEADSTATE.ASSIGNED);

        return this._getPositionTop(screenHeight, screenWidth, this._getPositionLeft(leads, screenWidth)
        );
    }

    private _getPositionLeft(leads: LeadCard[], screenWidth: number): LeadCard[] {
        var result: LeadCard[] = [];

        for (var lead of leads) {
            var left: number;

            if (lead.daysUntilDeadline <= 10) 
            {
                if (lead.daysUntilDeadline < 0)
                    lead.daysUntilDeadline = 0;

                left = lead.daysUntilDeadline * (positionThreshold['10DAYS'] / 10);
            }
            else if (lead.daysUntilDeadline <= 20) 
            {
                left =
                    positionThreshold['10DAYS'] +
                    (lead.daysUntilDeadline % 10 == 0 ? 10 : lead.daysUntilDeadline % 10) *
                    (positionThreshold['20DAYS'] / 10);

            }
            else if (lead.daysUntilDeadline <= 30) 
            {
                left =
                    positionThreshold['10DAYS'] +
                    positionThreshold['20DAYS'] +
                    (lead.daysUntilDeadline % 10 == 0 ? 10 : lead.daysUntilDeadline % 10) *
                    (positionThreshold['30DAYS'] / 10);

            }
            else 
            {
                left = positionThreshold['10DAYS'] + positionThreshold['20DAYS'] + positionThreshold['30DAYS'];
            }


            // Check if the card left position will clip the viewport.
            let card_size = 200;
            let left_pos_px = (left * screenWidth) / 100;
            
            if (left_pos_px + card_size > screenWidth) 
            {
                let pos_aux = screenWidth - (card_size + 20);
                left = (pos_aux * 100) / screenWidth;
            }

            result.push({ ...lead, left: left, expired: lead.daysUntilDeadline > 0 ? false : true, });
        }
        return result;
    }

    calculateDaysUntilDeadline(deadline: string) {
        let today = new Date();
        let deadlineDate = new Date(deadline);
        let diff = deadlineDate.getTime() - today.getTime();
        return Math.ceil(diff / (1000 * 3600 * 24));
    }

    private _getPositionTop(screenHeight: number, screenWidth: number, leads: LeadCard[]): PositionResult {
        var top_baseline = 42;
        let top_level_increment = 95;
        var levels: ILevel[] = new Array<ILevel>();

        leads.forEach((lead, index) => {
            if (index == 0) {
                let pos_px = (lead.left * screenWidth) / 100;
                lead.top = top_baseline;
                levels.push({ id: [lead.lead.id], level: [pos_px] } as ILevel);
                return;
            }
            let result = this._sortLevels(lead, levels, 0, screenWidth);

            if (!result) {
                let pos_px = (lead.left * screenWidth) / 100;
                let size = levels.push({ id: [lead.lead.id], level: [pos_px] } as ILevel);
                lead.top = top_baseline + top_level_increment * (size - 1);
            }
        });
        return {
            leads: leads,
            level_tools: {
                level_count: levels.length,
                top_baseline: top_baseline,
                top_level_increment: top_level_increment,
            },
        };
    }

    private _sortLevels(lead: LeadCard, levels: ILevel[], n: number, screenWidth: number): boolean {
        if (n >= levels.length) return false;
        else {
            let pos_px = (lead.left * screenWidth) / 100;
            let card_size = 200;
            let top_baseline = 42;
            let top_level_increment = 95;
            let misses = 0;

            for (var value of levels[n].level) {
                if (!(pos_px >= value - 20 && pos_px <= value + card_size)) misses += 1;
            }

            if (misses == levels[n].level.length) {
                levels[n].id.push(lead.lead.id!);
                levels[n].level.push(pos_px);
                lead.top = top_baseline + top_level_increment * n;

                return true;
            }
        }
        return false || this._sortLevels(lead, levels, n + 1, screenWidth);
    }
}
