import { User } from "./identity.interface";

export enum PRIORITY {
    LOW = 1,
    MEDIUM = 2,
    HIGH = 3,
}

export enum LEADSTATE {
    OPENED = 1,
    ASSIGNED = 2,
    CANCELLED = 3,
    CLOSED = 4
}

export enum RECURRENCE {
    NONE = 1,
    HOUR = 2,
    DAY = 3,
    MONTH = 4,
    YEAR = 5,
}

export enum LeadType {
    TURNKEY = 1,
    OUTSOURCING = 2,
}

export enum Motive {
    UNABLETOREACH = 1,
    WRONGDATA = 2,
    NOINTEREST = 3,
    NOBUDGET = 4,
    NOFIT = 5,
    NOTREADYTOBUY = 6,
    NOAUTHORITY = 7,
    OTHER = 8,
}

export enum positionThreshold {
    '10DAYS' = 28,
    '20DAYS' = 27.5,
    '30DAYS' = 27.5,
}

export interface ILevel {
    id: string[];
    level: number[];
}

export interface Lead {
    id?: string;
    name: string;
    client: string;
    deadline: string;
    priority: PRIORITY;
    leadState: LEADSTATE;
    type: LeadType;
    description?: string;
    budget?: number;
    associatedManagerId: string;
    recurrence?: RECURRENCE;
    motive?: Motive;
    motiveDescription?: string;
    profileName?: string;
    talentCenterLink?: string;
    rate?: number;
    otherDescription?: string;
}

export interface LeadRequest {
    id?: string;
    name: string;
    client: string;
    deadline: string;
    budget?: number;
    recurrence?: RECURRENCE;
    priority: PRIORITY;
    associatedManagerId: string;
}

export interface LeadCard {
    lead: Lead;
    daysUntilDeadline: number;
    left: number;
    top: number;
    expired: boolean;
    managerInfo?: User;
}

export interface PositionResult {
    leads: LeadCard[];
    level_tools: {
        level_count: number;
        top_baseline: number;
        top_level_increment: number;
    };
}