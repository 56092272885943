/**
 * CACHE KEYS
 */

export const currentUserKey = 'currentUser';
export const currentCredentialsKey = 'credentials';
export const currentApplicantKey = 'currentApplicant';
export const currentApplicantsListFilters = 'applicantsListFilters';

/**
 * APP CONFIGS
 */

export const PAGESIZES: number[] = [5, 10, 20, 50];
