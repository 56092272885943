import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Filter, GenericListWrapper } from '@app/compass/interfaces/data-model';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'primeng/api';
import { map, Observable } from 'rxjs';
import { Applicant, ApplicantFromExternal, ApplicantUpdateRequest, DocumentMetadata, GenerateCVRequest, Job, JobResponse } from '../interfaces/talent.interface';

@Injectable()
export class TalentService {

  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private translateService: TranslateService
  ) {
  }

  createApplicant(body: Applicant): Observable<Applicant> {
    return this.http
      .delay(2000)
      .post<Applicant>(`${environment.routes.applicant()}`, body)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.applicant.create.title'),
            detail: this.translateService.instant('services.applicant.create.success')
          });

          return resp;
        })
      );
  }

  createJob(body: Job): Observable<Job> {
    return this.http
      .delay(2000)
      .post<Job>(`${environment.routes.job()}`, body)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.job.create.title'),
            detail: this.translateService.instant('services.job.create.success')
          });
          return resp;
        })
      );
  }

  getApplicants(filters?: Filter[], sort?: Filter, pageSize?: number, pageNumber?: number): Observable<GenericListWrapper<Applicant>> {
    var params = new HttpParams();

    if(filters)
      filters.forEach(filter => {
        params = params.append(filter.key, filter.value);
      });

    if(sort)
      params = params.append(sort.key, sort.value);

    if (pageSize !== undefined && pageNumber !== undefined){
      params = params.append("pageNumber", pageNumber);
      params = params.append("pageSize", pageSize);
    }

    return this.http
      .delay(2000)
      .get<GenericListWrapper<Applicant>>(`${environment.routes.applicant()}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  } 

  getJobs(filters?: Filter[], sort?: Filter, pageSize?: number, pageNumber?: number): Observable<GenericListWrapper<JobResponse>> {
    var params = new HttpParams();

    if(filters)
      filters.forEach(filter => {
        params = params.append(filter.key, filter.value);
      });

    if(sort)
      params = params.append(sort.key, sort.value);

    if (pageSize !== undefined && pageNumber !== undefined){
      params = params.append("pageNumber", pageNumber);
      params = params.append("pageSize", pageSize);
    }

    return this.http
      .delay(2000)
      .get<GenericListWrapper<JobResponse>>(`${environment.routes.job()}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  } 

  getJobInfo(id: string): Observable<Job> {
    var params = new HttpParams();

    return this.http
      .delay(2000)
      .get<Job>(`${environment.routes.job()}/${id}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  updateJob(id: string, body: Job): Observable<Job> {
    return this.http
      .delay(2000)
      .put<Job>(`${environment.routes.job()}/${id}`, body)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.job.update.title'),
            detail: this.translateService.instant('services.job.update.success')
          });

          return resp;
        })
      );
  }


  getJobApplicants(id: string, pageSize?: number, pageNumber?: number): Observable<GenericListWrapper<Applicant>> {
    var params = new HttpParams();

    return this.http
      .delay(2000)
      .get<GenericListWrapper<Applicant>>(`${environment.routes.job()}/${id}/applicants`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }


  getApplicantInfo(id: string, expand: boolean = false): Observable<Applicant> {
    var params = new HttpParams();

    if(expand)
      params = params.append('expand', 'all');

    return this.http
      .delay(2000)
      .get<Applicant>(`${environment.routes.applicant()}/${id}`, { params })
      .pipe(
        map((resp) => {
          return resp;
        })
      );
  }

  updateApplicant(id: string, body: ApplicantUpdateRequest): Observable<Applicant> {
    return this.http
      .delay(2000)
      .patch<Applicant>(`${environment.routes.applicant()}/${id}`, body)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.applicant.update.title'),
            detail: this.translateService.instant('services.applicant.update.success')
          });

          return resp;
        })
      );
  }

  getApplicantDocuments(id: string): Observable<DocumentMetadata[]> {
    return this.http
      .delay(2000)
      .noLoader()
      .get<DocumentMetadata[]>(`${environment.routes.applicant()}/${id}/documents`);
  }

  generateCV(id: string, generateCVRequest: GenerateCVRequest): Observable<null> {
    return this.http
      .delay(2000)
      .post<null>(`${environment.routes.applicant()}/${id}/lp`, generateCVRequest)
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.applicant.lp-generation.title'),
            detail: this.translateService.instant('services.applicant.lp-generation.success')
          });

          return resp;
        })
      );
  }

  generateNewAccess(id: string) {
    
    return this.http
      .delay(2000)
      .post<string>(`${environment.routes.applicant()}/${id}/new-access`, {})
      .pipe(
        map((resp) => {
          this.messageService.add({ 
            severity: 'success', 
            summary: this.translateService.instant('services.applicant.new-access.title'),
            detail: this.translateService.instant('services.applicant.new-access.success')
          });

          return resp;
        })
      );
  }

  hireCandidate(id: string): Observable<boolean> {
    return this.http
      .delay(2000)
      .post<boolean>(`${environment.routes.applicant()}/${id}/hire`, {})
      .pipe(
        map((resp) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('services.applicant.hire.title'),
            detail: this.translateService.instant('services.applicant.hire.success')
          });

          return true;
        })
      )
  }

  reopenProcess(id: string): Observable<boolean> {
    return this.http
      .delay(2000)
      .post<boolean>(`${environment.routes.applicant()}/${id}/enable`, {})
      .pipe(
        map((resp) => {
          this.messageService.add({
            severity: 'success',
            summary: this.translateService.instant('services.applicant.reopen.title'),
            detail: this.translateService.instant('services.applicant.reopen.success')
          });

          return true;
        })
      )
  }
}
